<!-- 
  Página com formulário para inserção de novos produtos.
-->

<script>
import router from "@/router";
import CampoTexto from '../../components/campos_dados/campo_texto';
import CampoSelect from '../../components/campos_dados/campo_select';
import TiposDeCampo from '../../components/campos_dados/tipos_de_campo';
import { baseApiUrl, showSuccess, showError } from '@/global'
import axios from "axios";
import moment from "moment";

/**
 * Campos básicos de informação para criação do produto.
 */
const camposBases = {
  codIdentificacao: new CampoTexto({
    nome: 'cod_identificacao',
    label: 'Código de identificação',
    icone: 'fas fa-file-alt',
    valor: '',
    regras: [],
  }),
  descricao: new CampoTexto({
    nome: 'descricao',
    label: 'Descrição',
    icone: 'fas fa-stream',
    valor: '',
    regras: [],
  }),
  modal: new CampoSelect({
    nome: 'modal',
    label: 'Modal',
    icone: 'fas fa-th-list',
    valor: '',
    itens: [],
    regras: [],
  }),
};

/**
 * Campos adicionais, dados restantes.
 */
const camposDadosRestantes = {
  limiteCubagem: new CampoTexto({
    nome: 'limite_cubagem',
    label: 'Limite de cubagem',
    icone: 'fas fa-cubes',
    valor: '',
    regras: [],
    numerico: true,
  }),
  marca: new CampoTexto({
    nome: 'marca',
    label: 'Marca',
    icone: 'fas fa-copyright',
    valor: '',
    regras: [],
  }),
  grupo: new CampoTexto({
    nome: 'grupo',
    label: 'Grupo',
    icone: 'fas fa-layer-group',
    valor: '',
    regras: [],
  }),
  totalAgregados: new CampoTexto({
    nome: 'total_agregados',
    label: 'Total agregados',
    icone: 'fas fa-poll-h',
    valor: '',
    regras: [],
    numerico: true,
  }),
  doca: new CampoSelect({
    nome: 'doca',
    label: 'Doca',
    icone: 'fas fa-folder',
    valor: '',
    itens: [],
    regras: [],
  }),
  embalamento: new CampoSelect({
    nome: 'embalamento',
    label: 'Habilitar regra de embalamento',
    icone: 'fas fa-scroll',
    valor: 'Não',
    itens: ['Sim', 'Não'],
    explicacao: 'O sistema encaminhará as medidas do produto para procura de caixa pré-cadastrada.',
    regras: [],
  }),
};

/**
 * Campos de dimensões para produtos, agregados, 
 * volumes extras etc.
 */
class Dimensoes {
  comprimento = new CampoTexto({
    nome: 'comprimento',
    label: 'Comprimento (m)',
    icone: 'fas fa-ruler-horizontal',
    valor: '',
    regras: [
      value => {
        value = Number(value);

        if (value <= 0)
          return 'Informe o comprimento.';

        return true;
      }
    ],
    numerico: true,
    aoMudar: () => {
      this.cubagem.valor = this.altura.valor * this.comprimento.valor * this.largura.valor;
    }
  });
  largura = new CampoTexto({
    nome: 'largura',
    label: 'Largura (m)',
    icone: 'fas fa-ruler',
    valor: '',
    regras: [
      value => {
        value = Number(value);

        if (value <= 0)
          return 'Informe a largura.';

        return true;
      }
    ],
    numerico: true,
    aoMudar: () => {
      this.cubagem.valor = this.altura.valor * this.comprimento.valor * this.largura.valor;
    }
  });
  altura = new CampoTexto({
    nome: 'altura',
    label: 'Altura (m)',
    icone: 'fas fa-ruler-vertical',
    valor: '',
    regras: [
      value => {
        value = Number(value);

        if (value <= 0)
          return 'Informe a altura.';

        return true;
      }
    ],
    numerico: true,
    aoMudar: () => {
      this.cubagem.valor = this.altura.valor * this.comprimento.valor * this.largura.valor;
    }
  });
  cubagem = new CampoTexto({
    nome: 'cubagem',
    label: 'Cubagem (m³)',
    icone: 'fas fa-cube',
    valor: 0,
    regras: [
      value => {
        value = Number(value);

        if (value <= 0)
          return 'Informe a cubagem.';

        return true;
      }
    ],
    bloqueado: true,
    numerico: true,
  });
  peso = new CampoTexto({
    nome: 'peso',
    label: 'Peso (kg)',
    icone: 'fas fa-weight-hanging',
    valor: '',
    regras: [
      value => {
        value = Number(value);

        if (value <= 0)
          return 'Informe o  peso.';

        return true;
      }
    ],
    numerico: true,
  });
  volume = new CampoTexto({
    nome: 'volume',
    label: 'Volume (UN)',
    icone: 'fas fa-fill',
    valor: '',
    regras: [
      value => {
        value = Number(value);

        if (value <= 0)
          return 'Informe o volume.';

        return true;
      }
    ],
    numerico: true,
  });
};

/** Campos de dimensões do novo produto. */
const camposDimensoes = new Dimensoes();

const estadoAgregados = {
  /** @type {?Dimensoes[]} */
  estadoAtual: [],

  qtd: 0,

  adicionar() {
    this.qtd++;
    this.estadoAtual.push(new Dimensoes());
  },

  /** @param {number} index */
  remover(index) {
    this.qtd--;
    this.estadoAtual.splice(index, 1);
  },
};

const estadoVolumesExtras = {
  /** @type {?Dimensoes[]} */
  estadoAtual: [],

  qtd: 0,

  adicionar() {
    this.qtd++;
    this.estadoAtual.push(new Dimensoes());
  },

  remover(index) {
    this.qtd--;
    this.estadoAtual.splice(index, 1);
  },
}

const camposAdicional = {
  prazo: new CampoTexto({
    nome: 'prazo',
    label: 'Prazo',
    icone: 'fas fa-calendar',
    valor: '',
    regras: [],
  }),
  valor: new CampoTexto({
    nome: 'valor',
    label: 'Valor',
    icone: 'fas fa-coins',
    valor: '',
    regras: [],
    numerico: true,
  }),
  tipo: new CampoSelect({
    nome: 'tipo',
    label: 'Tipo',
    icone: 'fas fa-window-restore',
    valor: '',
    itens: ['Valor', 'Porcentagem'],
    regras: [],
  }),
  calculo: new CampoSelect({
    nome: 'calculo',
    label: 'Cálculo',
    icone: 'fas fa-calculator',
    valor: '',
    itens: ['Frete', 'Produto'],
    regras: [],
  }),
};

/** 
 * Campos com valores de venda nos canais de venda.
 * TODO: deve puxar os canais de venda da API.
 */
const camposValoresVenda = {
  valor1: new CampoTexto({
    nome: 'valor1',
    label: 'Madeira Madeira',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor2: new CampoTexto({
    nome: 'valor2',
    label: 'Netshoes',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor3: new CampoTexto({
    nome: 'valor3',
    label: 'Leroy Merlin',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor4: new CampoTexto({
    nome: 'valor4',
    label: 'Via Varejo',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor5: new CampoTexto({
    nome: 'valor5',
    label: 'Convertize',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor6: new CampoTexto({
    nome: 'valor6',
    label: 'Carrefour',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor7: new CampoTexto({
    nome: 'valor7',
    label: 'Conecta',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor8: new CampoTexto({
    nome: 'valor8',
    label: 'Renner',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor9: new CampoTexto({
    nome: 'valor9',
    label: 'Shopee',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
};

async function gravarDados() {
  let url = `${baseApiUrl}/produtos-gravar`;
  let date = moment(new Date()).format("YYYY-MM-DD");
  let valor_venda = [];
  let agregados = [];
  let vol_extra = [];
  let tipo_adc = "";
  let calc_adc = "";

  if(this.camposAdicional.tipo.valor == 'Valor'){
    tipo_adc = "V";
  }else{
    if(this.camposAdicional.tipo.valor == 'Porcentagem'){
      tipo_adc = "P";
    }
  }

  if(this.camposAdicional.calculo.valor == 'Frete'){
    calc_adc = "F";
  }else{
    if(this.camposAdicional.calculo.valor == 'Produto'){
      calc_adc = "P";
    }
  }

  if(this.estadoAgregados.estadoAtual.length != 0){
    for(let cont = 0; cont < this.estadoAgregados.estadoAtual.length; cont++){
      agregados.push({
        "altura": this.estadoAgregados.estadoAtual[cont].altura.valor,
        "comprimento": this.estadoAgregados.estadoAtual[cont].comprimento.valor,
        "cubagem": this.estadoAgregados.estadoAtual[cont].cubagem.valor,
        "largura": this.estadoAgregados.estadoAtual[cont].largura.valor,
        "peso": this.estadoAgregados.estadoAtual[cont].peso.valor,
        "volume": this.estadoAgregados.estadoAtual[cont].volume.valor,
      });
    }
  }

  if(this.estadoVolumesExtras.estadoAtual.length != 0){
    for(let cont = 0; cont < this.estadoVolumesExtras.estadoAtual.length; cont++){
      vol_extra.push({
        "altura": this.estadoVolumesExtras.estadoAtual[cont].altura.valor,
        "comprimento": this.estadoVolumesExtras.estadoAtual[cont].comprimento.valor,
        "cubagem": this.estadoVolumesExtras.estadoAtual[cont].cubagem.valor,
        "largura": this.estadoVolumesExtras.estadoAtual[cont].largura.valor,
        "peso": this.estadoVolumesExtras.estadoAtual[cont].peso.valor,
        "volume": this.estadoVolumesExtras.estadoAtual[cont].volume.valor,
      })
    }
  }

  // let headers = ["Madeira_Madeira", "Netshoes", "Leroy_Merlin", "Via_Varejo", "Convertize", "Carrefour",
  //   "Conecta", "Renner"];

  // for(let cont = 0; cont < headers.length; cont++){
  //   valor_venda.push({headers[cont]: Object.values(this.camposValoresVenda)[cont].valor});
  // }

  valor_venda = ({
    "Madeira_Madeira": Object.values(this.camposValoresVenda)[0].valor,
    "Netshoes": Object.values(this.camposValoresVenda)[1].valor,
    "Leroy_Merlin": Object.values(this.camposValoresVenda)[2].valor,
    "Via_Varejo": Object.values(this.camposValoresVenda)[3].valor,
    "Convertize": Object.values(this.camposValoresVenda)[4].valor,
    "Carrefour": Object.values(this.camposValoresVenda)[5].valor,
    "Conecta": Object.values(this.camposValoresVenda)[6].valor,
    "Renner": Object.values(this.camposValoresVenda)[7].valor,
    "Shopee": Object.values(this.camposValoresVenda)[8].valor,
  });

  if(valor_venda.Madeira_Madeira == ""){
    valor_venda.Madeira_Madeira = 0;
  }
  if(valor_venda.Netshoes == ""){
    valor_venda.Netshoes = 0;
  }
  if(valor_venda.Leroy_Merlin == ""){
    valor_venda.Leroy_Merlin = 0;
  }
  if(valor_venda.Via_Varejo == ""){
    valor_venda.Via_Varejo = 0;
  }
  if(valor_venda.Convertize == ""){
    valor_venda.Convertize = 0;
  }
  if(valor_venda.Carrefour == ""){
    valor_venda.Carrefour = 0;
  }
  if(valor_venda.Conecta == ""){
    valor_venda.Conecta = 0;
  }
  if(valor_venda.Renner == ""){
    valor_venda.Renner = 0;
  }
  if(valor_venda.Shopee == ""){
    valor_venda.Shopee = 0;
  }

  if(this.camposDadosRestantes.embalamento.valor == "Não")
    this.embalamentoFinal = 0;
  else
    this.embalamentoFinal = 1;

  await axios
    .post(url, {
      "cliente": this.codigo_empresa,
      "cod_identificacao": this.camposBases.codIdentificacao.valor,
      "descricao": this.camposBases.descricao.valor,
      "limite_cubagem": this.camposDadosRestantes.limiteCubagem.valor,
      "marca": this.camposDadosRestantes.marca.valor,
      "grupo": this.camposDadosRestantes.grupo.valor,
      "doca_id": this.camposDadosRestantes.doca.valor,
      "total_agregados": this.camposDadosRestantes.totalAgregados.valor,
      "embalamento": this.embalamentoFinal,
      "comprimento": this.camposDimensoes.comprimento.valor,
      "largura": this.camposDimensoes.largura.valor,
      "altura": this.camposDimensoes.altura.valor,
      "cubagem": this.camposDimensoes.cubagem.valor,
      "peso": this.camposDimensoes.peso.valor,
      "volume": this.camposDimensoes.volume.valor,
      "prazo": this.camposAdicional.prazo.valor,
      "valor": parseFloat(this.camposAdicional.valor.valor),
      "tipo": tipo_adc,
      "calculo": calc_adc,
      "preco_venda": {
        'valor1': parseFloat(valor_venda.Madeira_Madeira),
        'valor2': parseFloat(valor_venda.Netshoes),
        'valor3': parseFloat(valor_venda.Leroy_Merlin),
        'valor4': parseFloat(valor_venda.Via_Varejo),
        'valor5': parseFloat(valor_venda.Convertize),
        'valor6': parseFloat(valor_venda.Carrefour),
        'valor7': parseFloat(valor_venda.Conecta),
        'valor8': parseFloat(valor_venda.Renner),
        // 'Shopee': parseFloat(valor_venda.Shopee),
      },
      "agregados": agregados,
      "volume_extra": vol_extra,
      "criado_em": date
    }).then((res) => {
      this.$swal({
        title: 'Sucesso!!!',
        text: `${res.data.dados}`,
        icon: 'success',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#4BB543',
        allowOutsideClick: true,
      }).finally(() => (router.push({ path: '/cadastro-produtos' })));
    }).catch(() => {
      this.$swal({
        title: 'Que pena!!!',
        html: 'Não foi possível adicionar o produto.<br>Verifique se as informações estão corretas.',
        icon: 'warning',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#4BB543',
        allowOutsideClick: true,
      });
    })
}

function fecharPagina(){
  router.push({
    path: '/cadastro-produtos',
  });
}

function limparCampos(){
  this.camposBases.codIdentificacao.valor = '';
  this.camposBases.descricao.valor = '';
  this.camposBases.modal.valor = '';

  this.camposDadosRestantes.limiteCubagem.valor = '';
  this.camposDadosRestantes.marca.valor = '';
  this.camposDadosRestantes.grupo.valor = '';
  this.camposDadosRestantes.totalAgregados.valor = '';
  this.camposDadosRestantes.doca.valor = '';
  this.camposDadosRestantes.embalamento.valor = '';

  this.camposDimensoes.comprimento.valor = '';
  this.camposDimensoes.altura.valor = '';
  this.camposDimensoes.largura.valor = '';
  this.camposDimensoes.cubagem.valor = '';
  this.camposDimensoes.peso.valor = '';
  this.camposDimensoes.volume.valor = '';

  this.estadoAgregados.qtd = 0;
  this.estadoAgregados.estadoAtual = [];

  this.estadoVolumesExtras.qtd = 0;
  this.estadoVolumesExtras.estadoAtual = [];

  this.camposAdicional.prazo.valor = '';
  this.camposAdicional.valor.valor = '';
  this.camposAdicional.tipo.valor = '';
  this.camposAdicional.calculo.valor = '';

  this.camposValoresVenda.valor1.valor = '';
  this.camposValoresVenda.valor2.valor = '';
  this.camposValoresVenda.valor3.valor = '';
  this.camposValoresVenda.valor4.valor = '';
  this.camposValoresVenda.valor5.valor = '';
  this.camposValoresVenda.valor6.valor = '';
  this.camposValoresVenda.valor7.valor = '';
  this.camposValoresVenda.valor8.valor = '';
}

export default {
  name: 'InserirProduto',

  data: () => ({
    codigo_empresa: null,
    embalamentoFinal: null,

    TiposDeCampo,
    camposBases,
    camposDimensoes,
    camposAdicional,
    camposValoresVenda,
    camposDadosRestantes,
    estadoAgregados,
    estadoVolumesExtras,
  }),

  methods: {
    fecharPagina,
    limparCampos,
    gravarDados,
  },
  
  created() {
    this.codigo_empresa = Number(this.$store.state.cod_empresa);
    this.limparCampos();
  }
}

</script>

<template>
  <article>
    <!-- Título. -->
    <v-toolbar flat>
      <v-icon size="100">
        fas fa-plus
      </v-icon>
      <v-toolbar-title class="pl-4" style="font-size: 24px">
        Inserir produto
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click="fecharPagina">
        <v-icon>fas fa-times</v-icon>
      </v-app-bar-nav-icon>
    </v-toolbar>

    <v-form ref="form" @submit.prevent="gravarDados" style="margin: 5px">
      <!-- Campos bases. -->
      <v-card outlined>
        <v-card-title class="subtitle-1">Produto</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="6" md="3" v-for="campo in camposBases" :key="campo.nome">
              <v-text-field outlined dense v-if="campo.tipoDeCampo == TiposDeCampo.texto" @click="campo.aoClicar"
                :disabled="campo.bloqueado" :type="campo.numerico ? 'number' : 'text'" :append-icon="campo.icone"
                :name="campo.nome" :label="campo.label" autocomplete="off" v-model="campo.valor" :rules="campo.regras"
                color="#018656" @change="campo.aoMudar">
              </v-text-field>

              <v-select outlined dense v-else-if="campo.tipoDeCampo == TiposDeCampo.select" @click="campo.aoClicar"
                :disabled="campo.bloqueado" :append-icon="campo.icone" :name="campo.nome" :label="campo.label"
                autocomplete="off" v-model="campo.valor" :rules="campo.regras" :items="campo.itens" color="#018656"
                @change="campo.aoMudar">
              </v-select>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-btn type="submit" color="#018656" style="color: #FFFFFF; width: 100%; height: 38px;">
                <v-icon dense class="mx-2">fas fa-check-circle</v-icon>
                GRAVAR DADOS
              </v-btn>
            </v-col>
          </v-row>

          <!-- Demais campos. -->
          <v-row dense align="center">
            <v-col cols="12" sm="6" md="3" v-for="campo in camposDadosRestantes" :key="campo.nome">
              <v-text-field dense outlined v-if="campo.tipoDeCampo == TiposDeCampo.texto" @click="campo.aoClicar"
                :disabled="campo.bloqueado" :type="campo.numerico ? 'number' : 'text'" :append-icon="campo.icone"
                :name="campo.nome" :label="campo.label" autocomplete="off" v-model="campo.valor" :rules="campo.regras"
                color="#018656" @change="campo.aoMudar">
              </v-text-field>

              <abbr v-else-if="campo.tipoDeCampo == TiposDeCampo.select" :title="campo.explicacao"
                style="text-decoration: none;">
                <v-select dense outlined @click="campo.aoClicar" :disabled="campo.bloqueado" :append-icon="campo.icone"
                  :name="campo.nome" :label="campo.label" autocomplete="off" v-model="campo.valor" :rules="campo.regras"
                  :items="campo.itens" color="#018656" @change="campo.aoMudar">
                </v-select>
              </abbr>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Campos de dimensões do produto. -->
      <v-card outlined style="margin-top: 10px;">
        <v-card-title class="subtitle-1">Dimensões</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="3" v-for="campo in camposDimensoes" :key="campo.nome">
              <v-text-field dense v-if="campo.tipoDeCampo == TiposDeCampo.texto" @click="campo.aoClicar"
                :disabled="campo.bloqueado" :type="campo.numerico ? 'number' : 'text'" :append-icon="campo.icone"
                :name="campo.nome" :label="campo.label" autocomplete="off" v-model="campo.valor" :rules="campo.regras"
                color="#018656" @change="campo.aoMudar">
              </v-text-field>

              <v-select dense v-else-if="campo.tipoDeCampo == TiposDeCampo.select" @click="campo.aoClicar"
                :disabled="campo.bloqueado" :append-icon="campo.icone" :name="campo.nome" :label="campo.label"
                autocomplete="off" v-model="campo.valor" :rules="campo.regras" :items="campo.itens" color="#018656"
                @change="campo.aoMudar">
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Adicional. -->
      <v-card outlined style="margin-top: 10px;">
        <v-card-title class="subtitle-1">Adicional</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="3" v-for="campo in camposAdicional" :key="campo.nome">
              <v-text-field dense v-if="campo.tipoDeCampo == TiposDeCampo.texto" @click="campo.aoClicar"
                :disabled="campo.bloqueado" :type="campo.numerico ? 'number' : 'text'" :append-icon="campo.icone"
                :name="campo.nome" :label="campo.label" autocomplete="off" v-model="campo.valor" :rules="campo.regras"
                color="#018656" @change="campo.aoMudar">
              </v-text-field>

              <v-select dense v-else-if="campo.tipoDeCampo == TiposDeCampo.select" @click="campo.aoClicar"
                :disabled="campo.bloqueado" :append-icon="campo.icone" :name="campo.nome" :label="campo.label"
                autocomplete="off" v-model="campo.valor" :rules="campo.regras" :items="campo.itens" color="#018656"
                @change="campo.aoMudar">
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Valor de venda. -->
      <v-card outlined style="margin-top: 10px;">
        <v-card-title class="subtitle-1">Valor de venda (R$)</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="6" sm="2" md="2" v-for="campo in camposValoresVenda" :key="campo.nome">
              <p style="font-weight: bold;">{{ campo.label }}</p>
              <v-text-field outlined dense v-if="campo.tipoDeCampo == TiposDeCampo.texto" @click="campo.aoClicar"
                :disabled="campo.bloqueado" :type="campo.numerico ? 'number' : 'text'" :append-icon="campo.icone"
                :name="campo.nome" autocomplete="off" v-model="campo.valor" :rules="campo.regras" color="#018656"
                @change="campo.aoMudar" hide-details>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Agregados. -->
      <v-card outlined style="margin-top: 10px;">
        <div class="d-flex align-center pa-1">
          <v-card-title class="subtitle-1">Agregados</v-card-title>
        </div>
        <v-card-text>
          <!-- Adicionar agregado. -->
          <v-row dense style="margin-bottom: 5px;">
            <v-col cols="12">
              <v-btn outlined type="button" @click="
                estadoAgregados.adicionar()" color="#FF5252" style="color: #018656; width: 100%;">
                <v-icon dense class="mx-2">fas fa-plus</v-icon>
                ADICIONAR AGREGADO
              </v-btn>
            </v-col>
          </v-row>

          <!-- Agregado. -->
          <v-card outlined v-for="(agregado, index) in estadoAgregados.estadoAtual" :key="index"
            style="margin-bottom: 15px;">
            <v-card-text>
              <v-row dense style="margin-top: 5px;">
                <v-col cols=" 12" sm="6" md="3" v-for="campo in agregado" :key="campo.nome">
                  <v-text-field dense outlined v-if="campo.tipoDeCampo == TiposDeCampo.texto" @click="campo.aoClicar"
                    :disabled="campo.bloqueado" :type="campo.numerico ? 'number' : 'text'" :append-icon="campo.icone"
                    :name="campo.nome" :label="campo.label" autocomplete="off" v-model="campo.valor" :rules="campo.regras"
                    color="#018656" @change="campo.aoMudar">
                  </v-text-field>
                </v-col>
              </v-row>

              <!-- Excluir agregado. -->
              <v-row dense v-if="estadoAgregados.qtd > 0" style="margin-bottom: 5px;">
                <v-col cols="12" sm="6" md="3">
                  <v-btn outlined type="button" @click="
                    estadoAgregados.remover(index)" color="#FF5252" style="color: #FF5252; width: 100%;">
                    <v-icon dense class="mx-2">fas fa-trash-alt</v-icon>
                    EXCLUIR AGREGADO
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>


      <!-- Volumes extras. -->
      <v-card outlined style="margin-top: 10px;">
        <div class="d-flex align-center pa-1">
          <v-card-title class="subtitle-1">Volumes extras</v-card-title>
        </div>
        <v-card-text>
          <!-- Adicionar volume extra. -->
          <v-row dense style="margin-bottom: 5px;">
            <v-col cols="12">
              <v-btn outlined type="button" @click="
                estadoVolumesExtras.adicionar()" color="#FF5252" style="color: #018656; width: 100%;">
                <v-icon dense class="mx-2">fas fa-plus</v-icon>
                ADICIONAR VOLUME EXTRA
              </v-btn>
            </v-col>
          </v-row>

          <!-- Volume extra. -->
          <v-card outlined v-for="(volumesExtras, index) in estadoVolumesExtras.estadoAtual" :key="index"
            style="margin-bottom: 15px;">
            <v-card-text>
              <v-row dense style="margin-top: 5px;">
                <v-col cols=" 12" sm="6" md="3" v-for="campo in volumesExtras" :key="campo.nome">
                  <v-text-field dense outlined v-if="campo.tipoDeCampo == TiposDeCampo.texto" @click="campo.aoClicar"
                    :disabled="campo.bloqueado" :type="campo.numerico ? 'number' : 'text'" :append-icon="campo.icone"
                    :name="campo.nome" :label="campo.label" autocomplete="off" v-model="campo.valor" :rules="campo.regras"
                    color="#018656" @change="campo.aoMudar">
                  </v-text-field>
                </v-col>
              </v-row>

              <!-- Excluir volume extra. -->
              <v-row dense v-if="estadoVolumesExtras.qtd > 0" style="margin-bottom: 5px;">
                <v-col cols="12" sm="6" md="3">
                  <v-btn outlined type="button" @click="
                    estadoVolumesExtras.remover(index)" color="#FF5252" style="color: #FF5252; width: 100%;">
                    <v-icon dense class="mx-2">fas fa-trash-alt</v-icon>
                    EXCLUIR VOLUME EXTRA
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-form>
  </article>
</template>